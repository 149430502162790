.p-progressbar-determinate .p-progressbar-value-animate {
  transition: width 100ms ease-in-out;
}

.ui-menu-child {
  left: -200px !important;
}

.p-tieredmenu .p-submenu-list {
  left: -100%; /* Adjust this value as needed to position the submenu to the left */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.app {
  display: grid;                     
  grid-template-areas:
      "navbar"
      "content";
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  width: 100%;                       
  height: 100vh;                
  overflow: hidden;
}

.navbar {
  grid-area: navbar;
}

.content {
  grid-area: content;
  display: grid;
  overflow-y: hidden;
}

.layout-datatable {
  display: grid;
  grid-template-areas:
      "header header header"
      "sidebar sidebar-controls section"
      "sidebar sidebar-controls footer";
  grid-template-columns: auto auto 1fr;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
}

.layout-datatable .header {
  grid-area: header;
}

.layout-datatable .sidebar {
  grid-area: sidebar;
  overflow: scroll;
}

.layout-datatable .toggle-sidebar-btn {
  grid-area: sidebar-controls;
  cursor: pointer;
}

.layout-datatable .section {
  grid-area: section;
  overflow: scroll;
}

.layout-datatable .paginator {
  grid-area: footer;
}

.tag {
  width: min-content;
}

.diff-tag {
  text-wrap: nowrap;
}

.difft-approved-tag {
  text-decoration: line-through;
}