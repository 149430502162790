.es-filter-view-fieldset .p-fieldset-legend a {
    font-size: 1rem !important;
    padding: 0.5rem;
}

.es-filter-view-fieldset .p-fieldset-legend {
    border-width: 1px;
}

.es-filter-view-fieldset .p-fieldset-content {
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.es-filter-view-fieldset .p-fieldset {
    margin: 1px;
    padding: 1px;
}

/* .es-filter-view-fieldset {
    margin: 1px;
    padding: 1px;
} */

.es-facet-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.es-sort-by {
    font-weight: bold;
    margin: 0px;
    padding: 0px;
    width: 100%;
}

.es-tree-select-facet {
    width: 100%;
}

.es-checkbox {
    display: flex;
    align-items: center;
    gap: 4px;
}

.es-active-facet-value {
    font-weight: bold;
}

.layout-es-query-rules {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 2px;
    margin: 2px;
}

.layout-es-query-rules .es-sort-by {
    font-weight: bold;
    width: 100%;
    gap: 8px;
    padding: 10px;
}

.layout-es-query-rules .p-tabview-panels {
    padding: 0px;
    margin: 0px;
}

.layout-es-query-rules .facets-filters {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px;
    margin: 0px;
}

.layout-es-query-rules .facets {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.layout-labeled-section {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 2px;
    margin: 2px;
}

.layout-labeled-section .legend {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid #DDD;
}


.layout-labeled-section .label {
    font-weight: bold;
    font-size: 1.1em;
    color: #444;
    padding-bottom: 10px;
    border-bottom: 2px solid #444;
    width: fit-content;
}

.extraction-priority-editor {
    display: flex;
}

.extraction-priority-editor .button {
    padding: 0px;
    margin: 0px;
}

.number-editor {
    display: flex;
}

.number-editor .button {
    padding: 0px;
    margin: 0px;
}

.extraction-priority-editor .non-active .extraction-priority-1 {
    background-color: #99e699;
    border: none;
}

.extraction-priority-editor .non-active .extraction-priority-2 {
    background-color: #d4f28d;
    border: none;
}

.extraction-priority-editor .non-active .extraction-priority-3 {
    background-color: #ffdb70;
    border: none;
}

.extraction-priority-editor .non-active .extraction-priority-4 {
    background-color: #ffcc99;
    border: none;
}

.extraction-priority-editor .non-active .extraction-priority-5 {
    background-color: #ff9999;
    border: none;
}

.navigation-group {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto; 
}

.navigation-group .image-button {
    margin: 0px;
    padding: 2px;
    color: var(--gray-700) !important;
    transition: background-color 0.3s ease;
}

.navigation-group .button {
    text-align: center;
    margin: 2px;
    border-radius: 1; 
    color: var(--gray-700) !important;
    transition: background-color 0.3s ease;
    white-space: nowrap;
}

.navigation-group .active {
    background-color: var(--gray-300) !important;
    color: var(--gray-700) !important;
}

.subnavigation-group {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto; 
}

.subnavigation-group .button {
    text-align: center;
    margin: 2px;
    border-left: 1px solid #999;
    border-right: 1px solid #999;
    border-radius: 1; 
    color: var(--gray-700) !important;
    transition: background-color 0.3s ease;
    white-space: nowrap;
}

.subnavigation-group .active {
    background-color: var(--gray-500) !important;
    color: var(--gray-50) !important;
}

.layout-dialog-datatable {
  display: grid;
  grid-template-areas:
      "header header header"
      "sidebar sidebar-controls section"
      "sidebar sidebar-controls footer";
  grid-template-columns: auto auto 1fr;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
}
