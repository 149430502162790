.category-root {
    background-color: #d2f0fa;
}
.category-new {
    background-color: #d4fed0;
}
.category-0 {
    background-color: #c9e6ff;
}
.category-1 {
    background-color: #d3eaff;
}
.category-2 {
    background-color: #ddefff;
}
.category-3 {
    background-color: #e6f3ff;
}

.category-assignable-0 {
    background-color: #fff8df;
}
.category-assignable-1 {
    background-color: #fff0bf;
}
.category-assignable-2 {
    background-color: #ffe99f;
}
.category-assignable-3 {
    background-color: #ffe27e;
}

.digitization-state-finished {
    background-color: #66bb6a;
}
.digitization-state-in-progress {
    background-color: #ffab40;
}
.digitization-state-planned {
    background-color: #880e4f;
}
.digitization-state-not-digitized {
    background-color: #757575;
}

.document-flag-is-extractable {
    background-color: #007bff;
}
.document-flag-already-extracted {
    background-color: #6c757d;
}
.document-flag-is-not-extractable {
    background-color: #fd7e14;
}
.document-flag-invalid-main-record {
    background-color: #dc3545;
}
.document-flag-invalid-cnb {
    background-color: #dc3545;
}
.document-flag-missing-page-count {
    background-color: #dc3545;
}

.document-already-exists {
    background-color: #007bff;
}

.digitization-forbidden {
    background-color: #dc3545;
}

.dt-periodical-tag {
    background-color: #007443;
}
.dt-volume-tag {
    background-color: #167a1b;
}
.dt-bundle-tag {
    background-color: #7c9b2c;
}
.dt-monograph-tag {
    background-color: #a5407f;
}
.dt-unit-tag {
    background-color: #c73565;
}
.dt-graphic-tag {
    background-color: #DC2522;
}
.dt-map-tag {
    background-color: #735f32;
}
.dt-music-tag {
    background-color: #af580b;
}
.dt-other-tag {
    background-color: #3f4e4f;
}
.dt-invalid-tag {
    background-color: #fd7e14;
}

.tag-not-found {
    background-color: #dc3545;
}
.tag-validity-true {
    background-color: #66bb6a;
}
.tag-validity-false {
    background-color: #dc3545;
}

.difft-missingfield-tag {
    background-color: #9b59b6;
}
.difft-extrafield-tag {
    background-color: #4a90e2;
}
.difft-differentvalue-tag {
    background-color: #fd7e14;
}
.difft-approved-tag {
    background-color: #66bb6a;
}
.difft-equal-tag {
    background-color: #66bb6a;
}
.tag-catalog-record-without-cnb {
    background-color: #66bb6a;
}
