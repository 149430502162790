.iwiki {
    display: grid;
    grid-template-areas:
        "bread-crumb bread-crumb"
        "panel-menu content";
    grid-template-columns: auto 1fr; 
    grid-template-rows: auto auto; 
    overflow: hidden; 
}

.iwiki .bread-crumb {
    grid-area: bread-crumb; 
    height: min-content;
}

.iwiki .panel-menu {
    grid-area: panel-menu; 
    padding: 10px;
}

.iwiki .content {
    grid-area: content; 
    overflow: scroll; 
    padding: 20px;
    margin: 10px;
    border: 2px solid #ddd;
    max-height: calc(100vh - 137px);
}
