.Board {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 150px; */
}

.clickable-row {
  cursor: pointer;
}

.p-inputtext {
    width: 100%;
}

.non-selectable .p-selection-column {
  display: none;
}

.overflow-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.extraction-priority-1 {
    background-color: #66b266; /* Darker green */
    border: 2px solid #558a55; /* Slightly darker green */
}

.extraction-priority-2 {
    background-color: #99cc33; /* Olive green */
    border: 2px solid #88b02d; /* Slightly darker olive green */
}

.extraction-priority-3 {
    background-color: #ffcc00; /* Strong yellow */
    border: 2px solid #e6b800; /* Slightly darker yellow */
}

.extraction-priority-4 {
    background-color: #ff944d; /* Burnt orange */
    border: 2px solid #e68544; /* Slightly darker burnt orange */
}

.extraction-priority-5 {
    background-color: #ff4d4d; /* Bright red */
    border: 2px solid #e64545; /* Slightly darker red */
}

/* button {
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
} */

/* table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    table-layout: fixed;
} */

/* th { */
    /* background-color: #f2f2f2;
    font-weight: bold;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

td {
    padding: 8px;
    border: 1px solid #ddd;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
}

tr:hover {
    background-color: #ddd;
} */
